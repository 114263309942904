import * as React from "react"
import { addPropertyControls, ControlType } from "framer"

export default function PricingMatrix(props) {
    const {
        maxWidth = 900,
        primaryButtonLink = "/contact",
        contactButtonText = "Contact sales",
        getStartedButtonText = "Get started",
        customCreditsButtonText = "Custom credits",
        comparePlansTitle = "Compare our plans",
        billedYearlyText = "Billed yearly. All credits granted upfront.",
        mostPopularText = "MOST POPULAR",
        bestValueText = "BEST VALUE",
        usersUnitText = "users",
        monthUnitText = "/month",
        customText = "Custom",
    } = props

    // State hooks
    const [openDropdown, setOpenDropdown] = React.useState(null)
    const [isMobileView, setIsMobileView] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState(1) // For mobile view tabs (default to Premium)
    const [localizedButtonLink, setLocalizedButtonLink] =
        React.useState(primaryButtonLink)

    // Initialize selected packages
    const [selectedPackages, setSelectedPackages] = React.useState({
        basic: { users: 50, price: props.pricesBasic50 },
        premium: { users: 100, price: props.pricesPremium100 },
        pro: { users: 500, price: props.pricesPro500 },
        enterprise: { users: null, price: null },
    })

    // Refs for container
    const containerRef = React.useRef(null)

    // Brand colors
    const colors = {
        green: "#C4EE4D",
        lighterGreen: "#EAFABE",
        blue: "#0051AC",
        lighterBlue: "#D0E2F3",
        pageColor: "#161616",
        lightGray: "#1A1A1A",
        lighterGray: "#1E1E1E",
        darkGray: "#121212",
        fontGray: "#F4F4F4",
        borderColor: "#333333",
    }

    // Plans data
    const plans = [
        {
            name: props.basicTierName || "Basic",
            id: "basic",
            packages: [
                { users: 50, price: props.pricesBasic50 },
                { users: 100, price: props.pricesBasic100 },
            ],
            popular: false,
            bestValue: false,
            bgColor: colors.lighterGray,
            headerColor: "transparent",
            textColor: "#FFFFFF",
        },
        {
            name: props.premiumTierName || "Premium",
            id: "premium",
            packages: [
                { users: 100, price: props.pricesPremium100 },
                { users: 200, price: props.pricesPremium200 },
                { users: 300, price: props.pricesPremium300 },
            ],
            popular: true,
            bestValue: false,
            bgColor: colors.lighterGray,
            headerColor: colors.green,
            textColor: "#FFFFFF",
            highlightColor: colors.green,
        },
        {
            name: props.proTierName || "Pro",
            id: "pro",
            packages: [
                { users: 500, price: props.pricesPro500 },
                { users: 1000, price: props.pricesPro1000 },
                { users: 1500, price: props.pricesPro1500 },
            ],
            popular: false,
            bestValue: true,
            bgColor: colors.lightGray,
            headerColor: colors.lighterBlue,
            textColor: "#FFFFFF",
            highlightColor: colors.lighterBlue,
        },
        {
            name: props.enterpriseTierName || "Enterprise",
            id: "enterprise",
            packages: [],
            popular: false,
            bestValue: false,
            bgColor: colors.darkGray,
            headerColor: colors.pageColor,
            textColor: "#FFFFFF",
        },
    ]

    // Features data
    const features = React.useMemo(
        () => [
            {
                name: props.featureProjects || "Projects",
                values: ["1", "1", "1", props.featureValueUnlimited],
            },
            {
                name: props.feature100Integrations || "100+ integrations",
                values: [true, true, true, true],
            },
            {
                name:
                    props.featureUnlimitedSharing || "Unlimited access sharing",
                values: [true, true, true, true],
            },
            {
                name: props.featureUnlimitedDoors || "Unlimited doors",
                values: [true, true, true, true],
            },
            {
                name: props.featurePlugins || "Plugins",
                values: [true, true, true, true],
            },
            {
                name: props.featureNfc || "NFC",
                values: [true, true, true, true],
            },
            {
                name: props.featureSubprojects || "Subprojects",
                values: [true, true, true, true],
            },
            {
                name: props.featureDoorbell || "Doorbell",
                values: [false, true, true, true],
            },
            {
                name: props.featureAccessGroups || "Access groups",
                values: [false, true, true, true],
            },
            {
                name: props.featureImportExport || "Import/export",
                values: [false, true, true, true],
            },
            {
                name: props.featureAdvancedMonitoring || "Advanced monitoring",
                values: [false, false, true, true],
            },
            {
                name: props.featureBrandedMobileKeys || "Custom branding",
                values: [false, false, false, true],
            },
            {
                name: props.featureDataAnalytics || "Data analytics",
                values: [false, false, false, true],
            },
            {
                name: props.featureApiSdk || "API/SDK",
                values: [false, false, false, true],
            },
            {
                name: props.featureWebhooks || "Webhooks",
                values: [false, false, false, true],
            },
            {
                name: props.featureActiveDirectory || "Active directory",
                values: [false, false, false, true],
            },
            {
                name: props.featureSlackSupport || "Dedicated Slack support",
                values: [false, false, false, true],
            },
            {
                name: props.featureSso || "SSO",
                values: [false, false, false, true],
            },
            {
                name: props.featureWallet || "Wallet keys",
                values: [false, false, false, true],
            },
        ],
        [props]
    )

    // Get localized version of link that preserves locale prefix
    const getLocalizedLink = (link) => {
        if (!link || typeof link !== "string") return "#"

        // Only process absolute paths that start with /
        if (!link.startsWith("/")) return link

        // Get current path to extract locale
        const currentPath = window.location.pathname
        const localeMatch = currentPath.match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//)

        // If we found a locale and the target link doesn't already have it
        if (localeMatch && localeMatch[1]) {
            const locale = localeMatch[1]

            // Check if target already has this locale
            if (!link.startsWith(`/${locale}/`)) {
                return `/${locale}${link}`
            }
        }

        // Return original if no locale found or already included
        return link
    }

    // Update localized button link when component mounts or URL changes
    React.useEffect(() => {
        setLocalizedButtonLink(getLocalizedLink(primaryButtonLink))

        // Add event listener for popstate to update link when navigation occurs
        const handlePopState = () => {
            setLocalizedButtonLink(getLocalizedLink(primaryButtonLink))
        }

        window.addEventListener("popstate", handlePopState)
        return () => window.removeEventListener("popstate", handlePopState)
    }, [primaryButtonLink])

    // Check if viewport is mobile width
    React.useEffect(() => {
        const checkViewportWidth = () => {
            setIsMobileView(window.innerWidth < 810)
        }

        // Initial check
        checkViewportWidth()

        // Add event listener for window resize
        window.addEventListener("resize", checkViewportWidth)

        // Clean up
        return () => window.removeEventListener("resize", checkViewportWidth)
    }, [])

    // Helper function to render checkmark or blank feature
    const renderValue = (value) => {
        if (typeof value === "boolean") {
            if (value) {
                return (
                    <div
                        style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: colors.fontGray,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: colors.pageColor,
                            margin: "0 auto",
                            fontSize: "8px",
                            fontWeight: "bold",
                        }}
                    >
                        ✓
                    </div>
                )
            } else {
                return (
                    <div style={{ color: "#666666", fontSize: "16px" }}></div>
                )
            }
        } else {
            return (
                <div style={{ fontWeight: "500", color: "#FFFFFF" }}>
                    {value}
                </div>
            )
        }
    }

    // Handle package selection
    const selectPackage = (planId, pkg) => {
        setSelectedPackages({
            ...selectedPackages,
            [planId]: pkg,
        })
        setOpenDropdown(null)
    }

    // Package dropdown
    const renderDropdown = (plan) => {
        const selected = selectedPackages[plan.id]

        return (
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "220px",
                    margin: "0 auto",
                }}
            >
                <div
                    onClick={() =>
                        setOpenDropdown(
                            openDropdown === plan.id ? null : plan.id
                        )
                    }
                    style={{
                        width: "100%",
                        position: "relative",
                        border: `1px solid ${colors.borderColor}`,
                        borderRadius: "4px",
                        padding: "10px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        backgroundColor: colors.fontGray,
                        color: colors.lightGray,
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 400,
                    }}
                >
                    {plan.id === "enterprise"
                        ? contactButtonText
                        : `${selected?.users} ${usersUnitText}`}
                    <span
                        style={{
                            position: "absolute",
                            right: "12px",
                            top: "50%",
                            transform: "translateY(-50%)",
                        }}
                    >
                        ▼
                    </span>
                </div>

                {openDropdown === plan.id && plan.packages.length > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                            backgroundColor: colors.lightGray,
                            border: `1px solid ${colors.borderColor}`,
                            borderTop: "none",
                            zIndex: 100,
                            boxShadow: "0 4px 6px rgba(0,0,0,0.3)",
                        }}
                    >
                        {plan.packages.map((pkg, i) => (
                            <div
                                key={i}
                                onClick={() => selectPackage(plan.id, pkg)}
                                style={{
                                    padding: "10px",
                                    paddingLeft: "16px",
                                    borderBottom:
                                        i < plan.packages.length - 1
                                            ? `1px solid ${colors.borderColor}`
                                            : "none",
                                    cursor: "pointer",
                                    backgroundColor:
                                        selected?.users === pkg.users
                                            ? colors.fontGray
                                            : colors.lightGray,
                                    fontSize: "12px",
                                    textAlign: "left",
                                    color:
                                        selected?.users === pkg.users
                                            ? colors.pageColor
                                            : colors.fontGray,
                                }}
                            >
                                {pkg.users} {usersUnitText}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }

    // Button component for consistent styling
    const Button = ({ text, outlined = false, customStyle = {} }) => {
        return (
            <a
                href={localizedButtonLink}
                style={{
                    width: "100%",
                    padding: "10px 0",
                    backgroundColor: outlined ? "transparent" : colors.fontGray,
                    color: outlined ? "#FFFFFF" : colors.pageColor,
                    border: outlined
                        ? "1px solid rgba(255,255,255,0.3)"
                        : "none",
                    borderRadius: "4px",
                    fontWeight: 400,
                    fontSize: "12px",
                    cursor: "pointer",
                    textDecoration: "none",
                    display: "block",
                    textAlign: "center",
                    boxSizing: "border-box",
                    margin: outlined ? "0 auto" : "0 auto 10px auto",
                    pointerEvents: outlined ? "none" : "auto",
                    ...customStyle,
                }}
            >
                {text}
            </a>
        )
    }

    // Render the tab selection for mobile view
    const renderMobileTabs = () => (
        <div
            style={{
                display: "flex",
                overflow: "auto",
                marginBottom: "16px",
                borderBottom: `1px solid ${colors.borderColor}`,
            }}
        >
            {plans.map((plan, index) => (
                <div
                    key={index}
                    onClick={() => setActiveTab(index)}
                    style={{
                        padding: "12px 20px",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: activeTab === index ? "600" : "400",
                        color:
                            activeTab === index ? colors.fontGray : "#999999",
                        borderBottom:
                            activeTab === index
                                ? `2px solid ${colors.fontGray}`
                                : "none",
                        marginBottom: "-1px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {plan.name}
                </div>
            ))}
        </div>
    )

    const renderMobilePlanCard = (plan, index) => {
        if (index !== activeTab) return null

        const selectedPkg = selectedPackages[plan.id]
        const headerBgColor = plan.popular
            ? colors.green
            : plan.bestValue
              ? colors.lighterBlue
              : colors.pageColor
        const headerText = plan.popular
            ? mostPopularText
            : plan.bestValue
              ? bestValueText
              : ""

        return (
            <div
                style={{
                    width: "100%",
                    border: `1px solid ${colors.borderColor}`,
                    borderRadius: "6px",
                    overflow: "hidden",
                    backgroundColor: plan.bgColor,
                    marginBottom: "16px",
                }}
            >
                {/* Header tag */}
                {(plan.popular || plan.bestValue) && (
                    <div
                        style={{
                            backgroundColor: headerBgColor,
                            padding: "8px 0",
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "600",
                            color: colors.pageColor,
                            letterSpacing: "0.5px",
                        }}
                    >
                        {headerText}
                    </div>
                )}

                {/* Plan content */}
                <div
                    style={{
                        padding: "24px 16px",
                        textAlign: "center",
                    }}
                >
                    {/* Plan name */}
                    <div
                        style={{
                            display: "inline-block",
                            backgroundColor: "rgba(255,255,255,0.1)",
                            padding: "4px 12px",
                            borderRadius: "20px",
                            fontSize: "14px",
                            fontWeight: 500,
                            marginBottom: "16px",
                            color: plan.textColor,
                        }}
                    >
                        {plan.name}
                    </div>

                    {/* Price */}
                    {plan.id === "enterprise" ? (
                        <div
                            style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                marginBottom: "8px",
                                color: plan.textColor,
                            }}
                        >
                            {customText}
                        </div>
                    ) : (
                        <div
                            style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                marginBottom: "8px",
                                color: plan.textColor,
                            }}
                        >
                            {selectedPkg?.price}
                            <span
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    color: "#999999",
                                }}
                            >
                                {monthUnitText}
                            </span>
                        </div>
                    )}

                    {/* Billing text */}
                    {plan.id !== "enterprise" && (
                        <div
                            style={{
                                fontSize: "14px",
                                color: "#999999",
                                marginBottom: "24px",
                            }}
                        >
                            {billedYearlyText}
                        </div>
                    )}

                    {/* Dropdown or buttons */}
                    {plan.id === "enterprise" ? (
                        <div style={{ marginTop: "24px" }}>
                            <Button
                                text={contactButtonText}
                                customStyle={{
                                    padding: "12px 0",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    marginBottom: "12px",
                                }}
                            />
                            <Button
                                text={customCreditsButtonText}
                                outlined={true}
                                customStyle={{
                                    padding: "12px 0",
                                    fontSize: "12px",
                                }}
                            />
                        </div>
                    ) : (
                        <div style={{ margin: "20px 0" }}>
                            {renderDropdown(plan)}
                        </div>
                    )}

                    {/* Features list */}
                    <div style={{ marginTop: "32px", textAlign: "left" }}>
                        {features.map((feature, i) => {
                            if (feature.values[index]) {
                                return (
                                    <div
                                        key={i}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "12px",
                                            color: colors.fontGray,
                                        }}
                                    >
                                        <div style={{ marginRight: "12px" }}>
                                            {renderValue(true)}
                                        </div>
                                        <div style={{ fontSize: "14px" }}>
                                            {feature.name}
                                        </div>
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>

                    {/* Call to action button */}
                    {plan.id !== "enterprise" && (
                        <Button
                            text={getStartedButtonText}
                            customStyle={{
                                padding: "12px 0",
                                fontSize: "12px",
                                fontWeight: "600",
                                marginTop: "24px",
                            }}
                        />
                    )}
                </div>
            </div>
        )
    }

    // Render desktop comparison table
    const renderDesktopView = () => (
        <div
            style={{
                border: `1px solid ${colors.borderColor}`,
                borderRadius: "6px",
                overflow: "hidden",
            }}
        >
            <h2
                style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "20px",
                    color: "#F6F6F6",
                }}
            >
                {comparePlansTitle}
            </h2>

            {/* Header container */}
            <div
                style={{
                    backgroundColor: colors.pageColor,
                }}
            >
                {/* Plan Headers */}
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "220px repeat(4, 1fr)",
                        gap: "0",
                    }}
                >
                    <div
                        style={{
                            height: "40px",
                            backgroundColor: colors.pageColor,
                        }}
                    ></div>
                    {plans.map((plan, i) => (
                        <div
                            key={`header-${i}`}
                            style={{
                                backgroundColor: plan.headerColor,
                                color: plan.textColor,
                                textAlign: "center",
                                padding: "14px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderLeft:
                                    i > 0
                                        ? `1px solid ${colors.borderColor}`
                                        : "none",
                            }}
                        >
                            {plan.popular && (
                                <div
                                    style={{
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        letterSpacing: "0.5px",
                                        color: colors.pageColor,
                                    }}
                                >
                                    {mostPopularText}
                                </div>
                            )}
                            {plan.bestValue && (
                                <div
                                    style={{
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        letterSpacing: "0.5px",
                                        color: colors.pageColor,
                                    }}
                                >
                                    {bestValueText}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Plan Cards */}
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "220px repeat(4, 1fr)",
                        gap: "0",
                    }}
                >
                    <div
                        style={{
                            height: "195px",
                            backgroundColor: colors.pageColor,
                        }}
                    ></div>
                    {plans.map((plan, i) => {
                        const selectedPkg = selectedPackages[plan.id]
                        return (
                            <div
                                key={`plan-${i}`}
                                style={{
                                    backgroundColor: plan.bgColor,
                                    color: plan.textColor,
                                    borderLeft:
                                        i > 0
                                            ? `1px solid ${colors.borderColor}`
                                            : "none",
                                    borderRight:
                                        i < plans.length - 1 ? "none" : "none",
                                    borderBottom: `1px solid ${colors.borderColor}`,
                                    padding: "12px 12px",
                                    textAlign: "center",
                                    height: "195px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent:
                                        plan.name ===
                                            props.enterpriseTierName ||
                                        plan.name === "Enterprise"
                                            ? "flex-start"
                                            : "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        display: "inline-block",
                                        backgroundColor:
                                            "rgba(255,255,255,0.1)",
                                        padding: "4px 12px",
                                        borderRadius: "20px",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        marginBottom: "24px",
                                        color: plan.textColor,
                                    }}
                                >
                                    {plan.name}
                                </div>

                                {plan.name === props.enterpriseTierName ||
                                plan.name === "Enterprise" ? (
                                    <div
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                            marginBottom: "0px",
                                            color: plan.textColor,
                                        }}
                                    >
                                        {customText}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                            marginBottom: "8px",
                                            color: plan.textColor,
                                        }}
                                    >
                                        {selectedPkg?.price}
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "normal",
                                                color: "#999999",
                                            }}
                                        >
                                            {monthUnitText}
                                        </span>
                                    </div>
                                )}

                                <div
                                    style={{
                                        fontSize: "12px",
                                        color: "#999999",
                                        marginBottom:
                                            plan.name ===
                                                props.enterpriseTierName ||
                                            plan.name === "Enterprise"
                                                ? "0px"
                                                : "16px",
                                        lineHeight: "1.4",
                                    }}
                                >
                                    {plan.name === props.enterpriseTierName ||
                                    plan.name === "Enterprise"
                                        ? ""
                                        : billedYearlyText}
                                </div>

                                {plan.name === props.enterpriseTierName ||
                                plan.name === "Enterprise" ? (
                                    <>
                                        <div
                                            style={{
                                                marginTop: "auto",
                                                width: "100%",
                                            }}
                                        >
                                            <Button text={contactButtonText} />
                                            <Button
                                                text={customCreditsButtonText}
                                                outlined={true}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    renderDropdown(plan)
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Feature Table - this is scrollable */}
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "220px repeat(4, 1fr)",
                    gap: "0",
                }}
            >
                {features.map((feature, i) => (
                    <React.Fragment key={`feature-row-${i}`}>
                        {/* Feature name cell */}
                        <div
                            style={{
                                padding: "14px 16px",
                                backgroundColor: colors.lighterGray,
                                borderBottom:
                                    i < features.length - 1
                                        ? `1px solid ${colors.borderColor}`
                                        : "none",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#CCCCCC",
                            }}
                        >
                            {feature.name}
                        </div>

                        {/* Feature values for each plan */}
                        {feature.values.map((value, j) => (
                            <div
                                key={`feature-${i}-plan-${j}`}
                                style={{
                                    padding: "14px",
                                    textAlign: "center",
                                    backgroundColor:
                                        j === 1 && plans[1].popular
                                            ? colors.lightGray
                                            : j === 2 && plans[2].bestValue
                                              ? colors.pageColor
                                              : j === 3
                                                ? colors.darkGray
                                                : colors.lighterGray,
                                    borderBottom:
                                        i < features.length - 1
                                            ? `1px solid ${colors.borderColor}`
                                            : "none",
                                    borderLeft:
                                        j > 0
                                            ? `1px solid ${colors.borderColor}`
                                            : "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "14px",
                                }}
                            >
                                {renderValue(value)}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )

    return (
        <div
            ref={containerRef}
            style={{
                fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                width: "100%",
                maxWidth: `${maxWidth}px`,
                margin: "0 auto",
                padding: "0",
                background: colors.pageColor,
                position: "relative",
            }}
        >
            {isMobileView ? (
                <div>
                    <h2
                        style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            margin: "20px 0",
                            color: "#F6F6F6",
                        }}
                    >
                        {comparePlansTitle}
                    </h2>

                    {renderMobileTabs()}

                    {plans.map((plan, index) =>
                        renderMobilePlanCard(plan, index)
                    )}
                </div>
            ) : (
                renderDesktopView()
            )}
        </div>
    )
}

addPropertyControls(PricingMatrix, {
    maxWidth: {
        type: ControlType.Number,
        title: "Max width",
        defaultValue: 900,
        min: 500,
        max: 1500,
    },
    primaryButtonLink: {
        type: ControlType.Link,
        title: "Button link",
        defaultValue: "/contact-sales",
    },
    // Text controls for internationalization
    comparePlansTitle: {
        type: ControlType.String,
        title: "Title",
        defaultValue: "Compare our plans",
    },
    contactButtonText: {
        type: ControlType.String,
        title: "Contact button text",
        defaultValue: "Contact sales",
    },
    getStartedButtonText: {
        type: ControlType.String,
        title: "Get started button text",
        defaultValue: "Get started",
    },
    customCreditsButtonText: {
        type: ControlType.String,
        title: "Custom credits text",
        defaultValue: "Custom credits",
    },
    billedYearlyText: {
        type: ControlType.String,
        title: "Billing text",
        defaultValue: "Billed yearly. All credits granted upfront.",
    },
    mostPopularText: {
        type: ControlType.String,
        title: "Most popular label",
        defaultValue: "MOST POPULAR",
    },
    bestValueText: {
        type: ControlType.String,
        title: "Best value label",
        defaultValue: "BEST VALUE",
    },
    usersUnitText: {
        type: ControlType.String,
        title: "Users unit",
        defaultValue: "users",
    },
    monthUnitText: {
        type: ControlType.String,
        title: "Month unit",
        defaultValue: "/month",
    },
    customText: {
        type: ControlType.String,
        title: "Custom price text",
        defaultValue: "Custom",
    },
    // Tier names
    basicTierName: {
        type: ControlType.String,
        title: "Basic tier name",
        defaultValue: "Basic",
    },
    premiumTierName: {
        type: ControlType.String,
        title: "Premium tier name",
        defaultValue: "Premium",
    },
    proTierName: {
        type: ControlType.String,
        title: "Pro tier name",
        defaultValue: "Pro",
    },
    enterpriseTierName: {
        type: ControlType.String,
        title: "Enterprise tier name",
        defaultValue: "Enterprise",
    },
    // Feature names
    featureProjects: {
        type: ControlType.String,
        title: "Feature: Projects",
        defaultValue: "Projects",
    },
    feature100Integrations: {
        type: ControlType.String,
        title: "Feature: 100+ integrations",
        defaultValue: "100+ integrations",
    },
    featureUnlimitedSharing: {
        type: ControlType.String,
        title: "Feature: Unlimited access sharing",
        defaultValue: "Unlimited access sharing",
    },
    featureUnlimitedDoors: {
        type: ControlType.String,
        title: "Feature: Unlimited doors",
        defaultValue: "Unlimited doors",
    },
    featurePlugins: {
        type: ControlType.String,
        title: "Feature: Plugins",
        defaultValue: "Plugins",
    },
    featureNfc: {
        type: ControlType.String,
        title: "Feature: NFC",
        defaultValue: "NFC",
    },
    featureDoorbell: {
        type: ControlType.String,
        title: "Feature: Doorbell",
        defaultValue: "Doorbell",
    },
    featureAccessGroups: {
        type: ControlType.String,
        title: "Feature: Access groups",
        defaultValue: "Access groups",
    },
    featureImportExport: {
        type: ControlType.String,
        title: "Feature: Import/export",
        defaultValue: "Import/export",
    },
    featureAdvancedMonitoring: {
        type: ControlType.String,
        title: "Feature: Advanced monitoring",
        defaultValue: "Advanced monitoring",
    },
    featureSubprojects: {
        type: ControlType.String,
        title: "Feature: Subprojects",
        defaultValue: "Subprojects",
    },
    featureBrandedMobileKeys: {
        type: ControlType.String,
        title: "Feature: Custom branding",
        defaultValue: "Custom branding",
    },
    featureDataAnalytics: {
        type: ControlType.String,
        title: "Feature: Data analytics",
        defaultValue: "Data analytics",
    },
    featureApiSdk: {
        type: ControlType.String,
        title: "Feature: API/SDK",
        defaultValue: "API/SDK",
    },
    featureWebhooks: {
        type: ControlType.String,
        title: "Feature: Webhooks",
        defaultValue: "Webhooks",
    },
    featureActiveDirectory: {
        type: ControlType.String,
        title: "Feature: Active directory",
        defaultValue: "Active directory",
    },
    featureSlackSupport: {
        type: ControlType.String,
        title: "Feature: Slack support",
        defaultValue: "Dedicated Slack support",
    },
    featureSso: {
        type: ControlType.String,
        title: "Feature: SSO",
        defaultValue: "SSO",
    },
    featureWallet: {
        type: ControlType.String,
        title: "Feature: Wallet keys",
        defaultValue: "Wallet keys",
    },
    // Feature values
    featureValueUnlimited: {
        type: ControlType.String,
        title: "Value: Unlimited",
        defaultValue: "Unlimited",
    },
    // Prices
    pricesBasic50: {
        type: ControlType.String,
        title: "Price: Basic50",
        defaultValue: "£99",
    },
    pricesBasic100: {
        type: ControlType.String,
        title: "Price: Basic100",
        defaultValue: "£149",
    },
    pricesPremium100: {
        type: ControlType.String,
        title: "Price: Premium100",
        defaultValue: "£349",
    },
    pricesPremium200: {
        type: ControlType.String,
        title: "Price: Premium140",
        defaultValue: "£449",
    },
    pricesPremium300: {
        type: ControlType.String,
        title: "Price: Premium200",
        defaultValue: "£549",
    },
    pricesPro500: {
        type: ControlType.String,
        title: "Price: Pro500",
        defaultValue: "£699",
    },
    pricesPro1000: {
        type: ControlType.String,
        title: "Price: Pro1000",
        defaultValue: "£1099",
    },
    pricesPro1500: {
        type: ControlType.String,
        title: "Price: Pro1500",
        defaultValue: "£1499",
    },
})
